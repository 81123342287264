@import "../../styles/core";

.raceDashboardContainer {
  height: 100vh !important;

  position: relative;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: $sailgp-dark-blue;

  .headerContainer {
    flex-grow: 0;
    flex-shrink: 0;
    height: 85px;
  }

  .grid {
    flex-grow: 1;
    flex-shrink: 1;

    .react-resizable-handle {
      z-index: 1;
      &::after {
        border-color: $color-white;
      }
    }
  }
}
