@import "../../../styles/core";

.alertDisplay {
    box-sizing: border-box;

    max-height: 100%;
    margin: 0;
    padding: 16px 16px 0 16px;
    overflow-y: auto;

    background-color: $sailgp-deap-ocean-blue;

    &::-webkit-scrollbar {
        width: 4px;
    }
  
      &::-webkit-scrollbar-thumb {
        background-color: $sailgp-grey-blue;
    }
  
    .alertListItem {
        display: flex;
        flex-direction: column;

        .alert {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: $color-white;
            font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            padding: 8px 0;
            list-style-type: none;
            display: flex;
            align-items: center;

            .alertIcons {
                min-width: 26px;
                font-size: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin-right: 8px;

                &.infoIcon {
                    font-size: 30px;
                }

                &.personIcon {
                    height: 30px;
                    width: 30px;
                }

                &.controlCameraIcon {
                    height: 30px;
                    width: 30px;
                }

                &.markRounding {
                    height: 30px;
                    width: 30px;
                }

                &.clockIcon {
                    color: $color-supercharge-red;
                    font-size: 30px;
                }

                &.boatIcons {
                    fill: $color-white;
                    stroke: $color-white;
                    height: 30px;
                }

                &.Penalty {
                    color: $color-white;
                    background-color: $color-alert-penalty;
                    height: 26px;
                }

                &.PenMsg {
                    color: $color-white;
                    background-color: $color-alert-penalty;
                    height: 26px;
                }

                &.Protest {
                    color: $color-alert-penalty;
                    background-color: $color-white;
                    border: solid 0.5px $color-alert-penalty;
                    height: 26px;
                }

                &.Other {
                    color: $color-white;
                    background-color: $color-team-gumtree-green;
                    height: 26px;
                }
            }

            .wrapper {
                display: flex;
                word-break: break-word;
                justify-content: space-between;
                width: 100%;

                .alertTime {
                    word-break: keep-all;
                    font-size: 12px;
                    font-weight: normal;
                    color: $color-white;
                }
            }

            .alertSizeMd & {
                font-size: 18px;
                line-height: 21px;

                .alertIcons {
                    min-width: 30px;
                    height: 30px;
                }

                .alertTime {
                    font-size: 16px;
                }
            }

            .alertSizeLg & {
                font-size: 22px;
                line-height: 25px;

                .alertIcons {
                    min-width: 34px;
                    height: 34px;
                }

                .alertTime {
                    font-size: 20px;
                }
            }
        }
    }
}
