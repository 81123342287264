@import "../../../../styles/core";

.videoControlsContainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  background-color: rgba(6, 26, 27, 0.3);
  display: none;
  z-index: 5;

  &.liveControlsPosition {
    bottom: 10%;
  }

  &.replayControlsPosition {
    bottom: 3%;
  }

  &.displayControls {
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;

    .videoActionsContainer {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .volumeContainer {
        width: 40%;

        .volumeSlider {
          --SliderColor: $sailgp-red;
          -webkit-appearance: none;
          height: 6px;
          border-radius: 4px;
          background-color: white;
          width: 60%;
        }

        /* --------------------------- webkit browsers */
        .volumeSlider::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $sailgp-red;
          overflow: visible;
          cursor: pointer;
        }
      }
    }
  }

  .sliderContainer {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;

    .scratchSlider {
      --SliderColor: red;
      -webkit-appearance: none;
      width: 95%;
      height: 6px;
      border-radius: 4px;
      background-color: white;
    }

    /* --------------------------- webkit browsers */
    .scratchSlider::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $sailgp-red;
      overflow: visible;
      cursor: pointer;
    }

  }

  .commentaryTypeSelector {
    display: flex;
    align-items: center;


    .commentary__button {
      font-size: 12px;
      width: 80px;
      height: 26px;
      transform: skewX(-10deg);
      margin-left: 4px;
      margin-right: 4px;
      color: #fff;
      border-radius: 0;

      &.true {
        background-color: #e23b25;
      }
    }
  }
}