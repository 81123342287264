@import '../../../../../styles/core';

.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgStyle {
  fill: "orange";
  font-size: 25px;
}

tspan.tooltip {
  position: relative;
  display: inline-block;


}

tspan.tooltiptext {
  visibility: hidden;

  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  top: -5px;
  left: 110%;
  width: 271px;
  height: 127px;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

tspan.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

tspan.tooltip:hover + tspan.tooltiptext {
  visibility: visible;
}

.raceSelectModal {
  position: relative;
  background-color: $sailgp-black;
  z-index: 15;
  max-height: 775px;
  width: 700px;

  .closeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: $color-supercharge-red;
    z-index: 22;
    border: none;
    cursor: pointer;
  }

  .modalInfoContainer {
    position: relative;

    .modalInfoBackground {
      display: flex;
      flex-direction: row;
      height: 240px;
      position: relative;

      .modalInfoBackgroundShadow {
        background-color: $sailgp-dark-blue;
        width: 70%;

        &:before {
          content: '';
          position: absolute;
          z-index: 10;
          background-image: linear-gradient(to right, $sailgp-dark-blue, transparent);
          top: 0;
          bottom: 0;
          left: 53%;
          width: 47%;
          height: 240px
        }
      }

      .modalInfoBackgroundImage {
        right: 0;
        width: 60%;
        background-position: bottom;
        background-size: cover;
      }
    }

    .modalInfo {
      position: absolute;
      top: 0;
      z-index: 20;
      color: $color-white;
      width: 100%;


      &--title-container {
        display: flex;
        width: 100%;
        height: 100%;
      }

      &--svgStyle {
        fill: "orange";
        font-size: 25px;
      }

      &--title {
        font-size: 45px;
        margin: 0 20px;
        width: 70%;
      }

      &--location {
        font-size: 20px;
        margin: 0 20px;
      }

      &--date {
        font-size: 24px;
        margin: 10px 20px;
      }

      &--description {
        font-size: 16px;
        margin: 0 20px;
      }

    }
  }

  .modalRacesContainer {
    overflow-y: auto;
    height: fit-content;
    max-height: 500px;

    &--option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
      margin: 0 20px;

      &:not(:last-child) {
        border-bottom: solid 1px $gray-50;
      }

      &__info {
        margin: 0 30px;
        width: 250px;

        &-title {
          color: $color-white;
          font-size: 24px;
          font-style: italic;
          font-weight: 600;
        }

        &-description {
          color: $gray-50;
        }

      }

      &__button-container {
        display: flex;
        align-items: center;
      }

      &__label {
        width: 120px;
        height: 40px;
        transform: skewX(-10deg);
        margin: 12px 12px;
        background-color: #e23b25;
        color: $color-white;
        border-radius: 0;
        cursor: default;
      }

      &__button {
        width: 120px;
        height: 40px;
        transform: skewX(-10deg);
        margin: 12px 12px;
        background-color: #e23b25;
        color: $color-white;
        border-radius: 0;

        &:hover {
          background-color: #010606;
        }

        &--sold-out {
          background-color: $gray-50;
          pointer-events: none;

        }
      }

      &__tickets {
      }

      &__img {
        width: 150px;

        &-landscape {
          width: 200px;
          height: 100px;
        }
      }
    }
  }
}

