@import "../../styles/core";

.footerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  background-image: none;
  padding: 0 36px;

  .links {
    display: flex;
    margin-left: 10px;

    li {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $color-white;
        text-decoration: none;
        display: inline-block;
      }
    }
  }
}
