@import "../../../styles/core";

.alertFilterControls {
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: 16px 16px 20px 16px;

    background-color: $sailgp-deap-ocean-blue;

    .clearFiltersButton {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: flex-start;

        margin-bottom: 16px;

        font-weight: bold;
        text-transform: none;
        font-size: 12px;

        color:$color-white;
        &:disabled {
            color: #AFADAD;
        }
    }

    .filterOptionsList {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;

        .filterOptionContainer {
            margin-bottom: 8px;
        }

        .filterOptionChip {
            background-color: $sailgp-deap-ocean-blue;
            color: $color-white;
            font-weight: medium;
            font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 14px;
            border-width: 1px;
            border-style: solid;

            border-color: #979797;

            &.selected {
                background-color: #ebebeb;
                color: $color-black;
            }

            .filterOptionSelectedTick {
                width: 14px;
                height: 14px;
                margin-left: 9px;

                color: $color-black;
            }
        }
    }

    .applyButton {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: stretch;

        &:not(:disabled) {
            background-color: $color-button-enabled;
            color: $color-white;
        }
    }
}