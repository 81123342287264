@import "../../styles/core";

.AlertWidgetContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    .header {
        flex-grow: 0;
        flex-shrink: 0;

        background-color: $sailgp-black;
        min-height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        text-transform: uppercase;
        color: $color-white;
        font-size: 14px;
        letter-spacing: -0.4px;
        font-style: italic;
        font-weight: 600;

        .headerText {
            margin-left: 16px;
            flex-grow: 1;
            flex-shrink: 1;
        }

        .showFilterControlsContainer {
            margin-right: 18px;
        }
        .showFilterControlsButton {
            color: $color-white;
            width: 20px;
            height: 20px;
        }

        .closeFilterControlsButton {
            color: $color-white;
            margin-right: 20px;
            width: 14px;
            height: 14px;
        }
    }

    .contentContainer {
        position: relative;
        background-color: $sailgp-deap-ocean-blue;

        flex-grow: 1;
        flex-shrink: 1;

        .alertDisplayContainer {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        .filterControlsContainer {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
        }
    }
}
