@import "../../styles/core";

.eventStreamWidgetContainer {
  height: 100%;
  width: 100%;
  background-color: $sailgp-deap-ocean-blue;

  .streamName{
    text-align: center;
    position: absolute;
    bottom: 5%;
    width: 100%;
    font-weight: bold;

  }

}
