@import "../../styles/core";

.overlayContainer {
  p {
    font-size: 21px;
    font-weight: bold;
    padding-left: 0;
  }

  h1 {
    font-style: italic;
    font-size: 50px;
    line-height: 45.5px;
  }

  h2 {
    font-size: 40px;
    font-style: italic;
    position: absolute;
    top: 18%;
  }

  .MuiPopover-paper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background: #061a1be6;
    margin: 0;
    padding: 0;
    font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-transform: uppercase;
    left: 0 !important;
    top: 0 !important;
    transform-origin: unset !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .raceEnded {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .returnButton {
    transform: skewX(-15deg);
    background-color: $color-supercharge-red;
    color: white;
    border-radius: 0;
    padding: 25px 30px;
    height: 0;
    margin-top: 20px;

    &:hover {
      background-color: white;
      color: $color-supercharge-red;
    }
  }
}