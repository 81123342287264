@import "../../../../styles/core";

.videoSelectorContainer {
  margin: 10px 8px 10px 8px;
  display: flex;
  justify-content: center;

  &.liveRaceView {
    justify-content: space-between;
    align-items: center;
  }


  .MuiInputBase-input {
    text-transform: uppercase;
    font-style: italic !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .MuiOutlinedInput-root {
    height: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
  }

  .broadcasterImageWrapper {
    display: flex;
    align-items: center;

    .dot {
      width: 18px;
      height: 18px;
      background-color: $sailgp-red;
      border-radius: 50%;
      margin-right: 10px;
    }

    .liveOn {
      width: 32px;
      line-height: 10px
    }

    .broadcasterLogo {
      width: 36px;
      margin-left: 2px;
    }
  }


}
