@import "../../styles/core";

.keyInformationWidgetContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .header {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $sailgp-black;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    color: $color-white;
    font-size: 14px;
    letter-spacing: -0.4px;
    font-style: italic;
    font-weight: 600;
    padding: 0 16px;

    .headerText {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
  .slackMessageContainer{
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    margin: 0;
    padding: 8px 16px 0 16px;
    overflow-y: auto;
    background-color: $sailgp-deap-ocean-blue;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $sailgp-grey-blue;
    }

    .slackNotificationDisplay{
      display: flex;
      flex-direction: column;

      .message{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.21;
        color: $color-white;
        padding: 8px 0;
        list-style-type: none;
        display: flex;
      }

      .timeAndUser{
        word-break: keep-all;
        font-size: 12px;
        font-weight: normal;
        color: $color-white;
        padding-bottom: 8px;
      }
    }
  }
}
