@import '../../styles/core.scss';

.raceScheduleContainer {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  background-color: $sailgp-dark-blue;
  justify-content: space-between;
}

