@import "../../styles/core";

.css-jkymtx-MuiPaper-root {
  background-color: unset;
}

.insightsBackgroundContainer {
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/background-cover.jpg");
  z-index: -1;
}

.insightsBackgroundContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 26, 27, 0.8);
  z-index: 0; /* Ensure the pseudo-element is above the background image */

}

.insightsBackgroundContainer.popoverOpen::before {
  z-index: 5;
  background-color: rgba(6, 26, 27, 0.9);
}

.registerFormContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  margin: 50px 0 50px;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(6, 26, 27, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(6, 26, 27, 1);
  }
}

.insightsForm {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 400px;
  font-family: Founders Grotesk, sans-serif;


  &__label {
    font-family: Founders Grotesk, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;

    &-top {
      margin-top: 0;
    }
  }

  &__label:not(&__label--not-required)::after {
    content: " *";
    color: #e23d26;
  }

  &__required {
    color: #e23d26;
  }

  &__text {
    z-index: 1;
    font-family: Founders Grotesk;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0em;
    color: $color-white;
    font-size: 20px;
    margin: 35px auto 50px;

    &-info {
      margin: unset;
    }

    &-bold {
      font-weight: bold;
      font-size: 24px;
      margin: 0 0 20px 0;
    }

    &-heading {
      font-size: 50px;
      font-style: italic;
      font-weight: 600;
      line-height: 60px;
      margin-top: 100px;
      margin-left: -10px;
    }

    &-heading1 {
      font-size: 40px;
      font-style: italic;
      font-weight: 600;
      line-height: 48px;
      margin-top: 40px;
      margin-left: -10px;

    }
  }

  &__infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
    margin-bottom: 20px;
  }

  &__divSection {
    margin: 35px auto 50px;
    z-index: 1;
    color: $color-white;
    max-width: 450px;
  }

  &__input, .react-datepicker-wrapper {
    margin: 10px 0 10px 0;
    height: 35px;
    font-size: 18px;
    border-radius: 6px;
    border: 2px solid #bfbfbf;
    background: none;
    color: $color-white;

    &--error {
      border-color: $color-supercharge-red;
      color: $color-supercharge-red;
    }
  }

  &__dropdown {
    margin: 10px 0 10px 0;
    height: 35px;
    font-size: 18px;
    border-radius: 6px;
    border: 2px solid #bfbfbf;
    background: black;
    color: $color-white;

    &--error {
      border-color: $color-supercharge-red;
      color: $color-supercharge-red;
    }
  }

  &__error {
    color: $color-supercharge-red;
    margin: 0;
    font-size: 14px;
  }

  &__submit {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 14px auto;
    width: 63px;
    height: 41px;
    font-size: 20px;


    &--disabled {
      color: #979797;
      cursor: not-allowed;
      font-size: 20px;
    }

    &__icon {

      width: 35px;
      height: 35px;
      padding-left: 13px
    }
  }
  &__resetPasswordLogin {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 100px auto;
    width: 63px;
    height: 41px;
    font-size: 20px;


    &--disabled {
      color: #979797;
      cursor: not-allowed;
      font-size: 20px;
    }

    &__icon {

      width: 35px;
      height: 35px;
      padding-left: 13px
    }
  }
  &__forgotPassword {
    text-align: right;
    font-family: Founders Grotesk;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 50px;

  }

  &__link {
    &:active, &:focus, &:hover, &:link, &:visited {
      color: $color-supercharge-red;
      background-image: linear-gradient(180deg, transparent 95%, $color-supercharge-red 0);
      background-repeat: no-repeat;
      background-size: 20px 100%;
      transition: background-size 0.25s ease-in-out;
      text-decoration: inherit;

    }

    &:active, &:focus, &:hover {
      background-size: 100% 100%;
    }
  }

  &__linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &--link {
      &:active, &:focus, &:hover, &:link, &:visited {
        color: $color-white;
        background-image: linear-gradient(180deg, transparent 95%, $color-white 0);
        background-repeat: no-repeat;
        background-size: 20px 100%;
        transition: background-size 0.25s ease-in-out;
        text-decoration: inherit;
        z-index: 1;
        font-size: 20px;
        margin-top: 15px;
      }

      &:active, &:focus, &:hover {
        background-size: 100% 100%;
      }
    }
  }

  &__title {
    z-index: 1;
    color: $color-white;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 27px;
    margin-top: 50px;

    &-large {
      font-size: 50px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    height: 100%;
    color: $color-white;

    .react-datepicker-ignore-onclickoutside {
      width: 100%;
      height: 100%;
    }

    input {
      border: none;
      font-size: 18px;
      background: none;
      color: $color-white;
    }
  }

  &__checkbox-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
    margin-bottom: unset;
  }

  &__custom-checkbox {
    appearance: none;
    margin-right: 10px;
    vertical-align: middle;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 2px solid #bfbfbf;
    border-radius: 8px;
    flex-shrink: 0;

    &:checked {
      background-color: #e23d26;
      border-color: #e23d26;
    }
  }

  &__buttonContainer {
    margin: 50px;
  }

  &__homepageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
    margin-top: 10px;
  }

  &__signUpSection, &__loginSection {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__signUpSection {
    flex-direction: column;
    height: 100%;
    justify-content: start;

  }

  &__section {
    width: 400px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  &__popoverContainer {
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 20;
    background-color: transparent;

    .insightsForm__popoverContent {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: white;
      transition: height 0.5s ease;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--container {
        height: 100%;
        margin: 0 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &--text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 80%;
        height: 0;
        overflow: hidden;

        h2 {
          margin: 0;
        }

        p {
          font-size: 18px;
          margin: 5px 0;
        }

        a {
          color: $color-supercharge-red;
        }
      }

      .insightsForm__popoverContent--text.popoverOpen {
        height: 100%;
      }

    }

    .insightsForm__contentCardsList {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      height: 45%;
      padding: 0;
      margin-bottom: 20px;
      margin-top: 0;

      .insightsForm__contentCard {
        width: 30%;
        height: 100%;
        border: 1px solid rgba(226, 226, 226, 1);
        border-radius: 12px;
        list-style-type: none;

        &--container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 20px;

          h3 {
            color: $color-supercharge-red;
            margin: 0;
          }

          p {
            text-align: center;
            //margin: 0;
          }
        }
      }

      .insightsForm__cardIcon {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }
    }

    .insightsForm__popoverContent.popoverOpen {
      height: 100%;
      transition: height 0.5s ease;
    }

    .insightsForm__popoverButton {
      position: absolute;
      width: 400px;
      height: 0;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      border-bottom: 50px solid #fa3b1d;
      color: #fff;
      cursor: pointer;
      font-family: FoundersGroteskWeb, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-size: .88889rem;
      font-weight: 600;
      letter-spacing: 2.1px;
      text-align: center;
      text-transform: uppercase;
      bottom: 0;
      line-height: normal;
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      padding: 0;
      background: transparent;
      border-top: none;
      transition: height 0.5s ease;


      &--content {
        margin: 17px;
      }

    }

    .insightsForm__popoverButton.popoverOpen {
      bottom: 100%;
      transition: bottom 0.5s ease;
    }


  }

  &__popoverContainer {
    &.popoverOpen {
      height: 60%;
      transition: height 0.5s ease;
    }

    transition: height 0.5s ease;
  }


}

.insightsForm__button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: FoundersGroteskWeb, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .88889rem;
  font-weight: 600;
  letter-spacing: 2.1px;
  line-height: 15px;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  position: relative;
  text-transform: uppercase;

  overflow: visible;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  margin-bottom: 20px;


  width: 387px;


  gap: 0px;
  border: 1px solid #FFFFFF;

  &.insightsForm__button--primary {
    background: #fa3b1d;
    border: none;
    color: #fff;
  }

  &.insightsForm__button--secondary {
    background-color: #061A1B;
    border-color: #061A1B;
    color: #FFFFFF;
    margin-bottom: 10px;

    &:hover {
      filter: brightness(80%);
    }
  }

  &.insightsForm__button--submit {
    background-color: $color-new-wave-blue;;
    border-color: $color-new-wave-blue;
    margin: 20px 0;
  }

  &.insightsForm__button--disabled {
    background-color: $gray-40;;
    border-color: $gray-40;
    cursor: not-allowed;
  }

  &.insightsForm__button--margin {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.insightsForm__button__label {
  display: inline-block;
  position: relative;
}

.insightsForm__button__save {
  width: 387px;
  height: 68px;
  gap: 0px;
  opacity: 0px;
  background: #66C1C4;
  margin-bottom: 20px;
  margin-top: 10px;

}

.emailVerification__textContainer {
  z-index: 1;
}

.settingsFormContainer {

  z-index: 1;
  display: flex;
  flex-direction: column;

  font-family: Founders Grotesk, sans-serif;

  height: auto;

  align-items: center;
  overflow-y: scroll;
  margin: 50px 0;

}