@import "../../styles/core";


.deleteFormContainer {

  z-index: 1;
  display: flex;
  flex-direction: column;
  font-family: Founders Grotesk, sans-serif;
  height: auto;
  align-items: center;
  overflow-y: scroll;
  margin: 50px 0;

}

.delete-text__section__heading {
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: inherit;
  text-transform: inherit;
  font-family: FoundersGroteskWeb, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-top: 150px;
}

.delete-text p {
  font-size: 18px;
}

.delete-text--subheading {
  margin-bottom: 20px;
}

.delete-text ul {
  list-style-type: none;
  margin: 0;
}

.delete-text ul li {
  padding: 0 0 20px 40px;
  position: relative;
  font-size: 18px;
}

.delete-text ul li:before {
  top: 10px;
  background-color: #66c1c4;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: .55556rem;
  left: 0;
  margin-left: .5rem;
  position: absolute;
  top: 5px;
  width: .55556rem;
}

.submit {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  margin-top: 40px;
  margin-bottom: 35px;
  width: auto;
  height: 41px;
  font-size: 20px;
  padding: 0 10px;
}

.delete-text a:active, .delete-text a:focus, .delete-text a:hover, .delete-text a:link, .delete-text a:visited {
  color: #e23b25;
  background-image: linear-gradient(180deg, transparent 95%, #e23b25 0);
  background-repeat: no-repeat;
  background-size: 20px 100%;
  transition: background-size .25s ease-in-out;
}

.l-grid__inner {
  display: grid;
  margin: 0;
  grid-gap: 20px;
  grid-gap: var(--l-grid-gutter-lg, 20px);
  grid-template-columns: repeat(12, minmax(0, 1fr));
}


.l-grid__cell--span-2, .l-grid__cell--span-2-lg {
  width: auto;
  grid-column-end: span 2;
}

.l-grid__cell--span-8, .l-grid__cell--span-8-lg {
  width: auto;
  grid-column-end: span 8;
}

.deleteForm__button {
  margin: 0;
}