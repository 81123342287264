
.logoContainer {
  z-index: 1;
  margin-top: -150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  &__extraMarginTop {
    margin-top: 150px;
  }
}

.insightsOracleLogo {
  width: 70%;
}