@import "../../styles/core";

.additionalUserDataModal {
  position: relative;
  min-height: 400px;
  height: auto;
  width: 600px;
  background-color: white;
  z-index: 12;
  padding-bottom: 70px;

  .insightsForm .react-datepicker__input-container input {
    color: $color-black !important;
  }
}

.modalImage {
  height: 200px;
  width: 100%;
  background-image: url('../../assets/insights_modal.png');
  background-size: cover;
  background-position: center;
  z-index: 10;
  position: relative;
  top: 0;
}

.modalTitleContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 15;
  width: 100%;
  padding: 20px 0;
  margin-top: -50px;
}

.modalTitle {
  text-transform: uppercase;
  color: $color-supercharge-red;
  z-index: 15;
  margin: unset;
  font-weight: normal;
  font-style: italic;
  text-align: center;

  &__bold {
    font-weight: bold;
  }
}

.modalForm {
  width: 100%;
  position: relative;
  z-index: 15;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__submit {
    width: 120px;
    height: 40px;
    transform: skewX(-10deg);
    margin: 24px 12px;
    background-color: $sailgp-red;
    color: $color-white;
    border-radius: 0;

    &:hover {
      background-color: $sailgp-red;
    }

    &--disabled {
      background-color: $gray-50;
      pointer-events: none;
    }
  }

  &__label {
    margin-top: 8px;
    font-size: 22px;
  }

  &__input {
    color: black;
    height: 35px;
    font-size: 20px;

    &--error {
      border-color: $color-supercharge-red;
      color: $color-supercharge-red;
    }
  }

  &__inputContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
  }
}

.additionalUserDataModal::before {
  content: "";
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent 0%, white 45%, white 100%);
  z-index: 14;
}