@import "../../styles/core";

.dashboardHeader {
  border-bottom: 2px solid $color-border-dark;

  height: 84px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: $sailgp-dark-blue;
  display: flex;
  justify-content: space-between;
  align-items: center;

  $header-padding-left: 13px;
  $header-padding-right: 17px;

  .leftContentContainer {
    z-index: 2;
    height: 100%;
  }

  .headerBrandingContainer {
    width: 28%;
    margin-left: 20px;
    background: inherit;
    border: none;
    outline: none;
    cursor: pointer;

    @include mq($from: xl) {
      width: 35%;
    }

  }

  .headerSecondaryBrandingContainer {
    position: absolute;
    right: 249px;
    top: 50%;
    transform: translateY(-50%);
  }

  .headerLocalTimeContainer {
    position: absolute;
    right: $header-padding-right;
    top: 50%;
    transform: translatey(-50%);
  }

  .headerNavigationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 10px;

    @include mq($until: 1185px) {
      margin: 0 16px;
      width: 15%;
    }

    .homeButton {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      padding-right: 20px;

      @include mq($until: 1180px) {
        padding-right: 10px;
      }
    }

    .selectControl {
      background-color: #e23b25;
      border-radius: 0 20px 0 0;
      width: 150px;
      height: 40px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      pointer-events: auto;
      font-size: 10px;

      @include mq($from: xl) {
        width: 200px;
        font-size: 14px;
      }


    }

    .indicatorsContainer {
      color: white;
      background-color: #e23b25;
      width: fit-content;
      height: 20px;
      cursor: pointer;
      margin: 10px 8px 10px 5px;
    }

    .indicatorSeparator {
      display: none;
    }

    .valueContainer {
      color: white;
      cursor: pointer;
      height: 40px;
      width: 120px;
      display: flex;
      padding-left: 10px;
      justify-content: center;
      align-items: center
    }

    .selectInput {
      display: none;
    }

    .singleValue, .selectPlaceholder {
      color: white;
      font-style: italic;
      font-weight: 600;
      cursor: pointer
    }

    .selectContainer {
      width: 135px;
      height: 40px;

      @include mq($from: xl) {
        width: 200px;
      }

    }

    .menuList {
      background-color: #050E0E;
    }

    .selectOption {
      background-color: #D9D9D9;
      margin-bottom: 5px;
      color: #061A1B;
      padding: 5px;
      font-style: italic;
      font-weight: 600;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .selectMenu {
      position: relative;
      font-size: 14px;
      top: 40px
    }

  }

  .dashboardProfile {
    display: flex;


    .dashboardButton {
      color: #fff;
      background-color: inherit;
      display: flex;
      text-decoration: none;
      align-items: center;
      margin-right: 10px;
      font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      flex-direction: column;
      cursor: pointer;

      &__settings {
        border: none;
      }

      &__icon {
        width: 26px;
        height: 26px;
        margin-bottom: 4px;
      }
    }
  }
}