@import '../../styles/core';

.fullLoadingIndicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.3);
    z-index: 1;

    .progressContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .MuiCircularProgress-colorPrimary {
            color: $color-supercharge-red;
        }
    }
}
