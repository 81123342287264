///* ========================================================================
//   #COLORS
//   ======================================================================== */

// The global settings file contains any project-wide variables for colors
// Each color variable should follow this format `$color-{set name}-{color name, shade, tint}`

// Primary colors
//
// Define here primary color sets. Using recognazible name as a name conventions is a best practice.
$color-supercharge-red: #e23b25;
$color-maroon: #6a1811;
$color-deep-ocean-blue: #061a1b;
$color-deep-ocean-blue-tint: #0a2627;
$color-deeper-ocean-blue: #022226;
$color-new-wave-blue: #66c1c4;
$color-white: #fff;
$color-white-two: #f5f5f5;
$color-black: #000;
$color-inspire-purple: #440099;
$color-dashboard-header-background: #eaeaea;

// Secondary colors
//
// Define here secondary color sets
// None yet :D
$color-facebook: #4565a2;
$color-instagram: #d93175;
$color-twitter: #3ab1db;
$color-youtube: #f50605;
$color-wechat: #2dc100;
$color-weibo: #d52b2b;
$color-linkedin: #0077b5;

// Team colors
//
// Define here all team colors. Should be in paris for each team;
$color-team-golden-wattle: #fed103;
$color-team-gumtree-green: #007934;
$color-team-liberty-red: #db0032;
$color-team-star-blue: #002D5D;
$color-team-vermillion-lantern: #fa3b1d;
$color-team-golden-dragon: #fed103;
$color-team-sovereign-silver: #818b94;
$color-team-jet-white: #fff;
$color-team-fraternity-blue: #0047ba;
$color-team-hinomaru-crimson: #e5064e;
$color-team-den-gray: #63666a;
$color-team-esp-red: #c81c13;

// Shades of gray
$gray-0: #fff;
$gray-5: #f8f8f8;
$gray-10: #e7e7e7;
$gray-30: #a7a7a7;
$gray-40: #8f8f8f;
$gray-50: #777;
$gray-60: #616161;
$gray-70: #4a4a4a;

// Medal colours
$color-medal-gold: #F9CA00;
$color-medal-silver: #6D6D6D;
$color-medal-bronze: #CB996A;

// Alert colours
$color-alert-protest: #e23b25;
$color-alert-penalty: #e23b25;


// Other colours we might want to reuse
$color-button-enabled: #007934;
$color-text-dark: #121315;


//reskin of app. new colours will be defined here and the above should be removed

$sailgp-black: #010606;
$sailgp-deap-ocean-blue: #061a1b;
$sailgp-red: #e23b25;
$sailgp-dark-blue: #030e0e;
$sailgp-grey-blue: #66c1c4;
$color-border-dark: #222C2C;