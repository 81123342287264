@import "../../styles/core";

.boatGraphProgressWidgetContainer {
  height: 100%;
  background-color: $color-deep-ocean-blue;

  &.finishLine {
    border-right: 2px solid #66c1c4;
  }
}

.chartLayout {
  height: 100%;
}

.apexcharts-yaxis{
  opacity: 0.5;
}