@import '../../../../../styles/core';

.eventSelectedContainer {
  position: fixed;
  width: 100%;
  height: 50vh; // Set default height to 50% of the viewport height
  z-index: 99;

  @media (min-width: 2304px) and (max-width: 2304px) and (min-height: 900px) and (max-height: 1440px) {
    height: 34vh; // Set height to 34% of the viewport height for MacBook 13 inch
    font-size: 9px;
  }

  &--video {
    cursor: pointer;
  }

  .eventSelectedBackground,
  .eventSelectedArea {
    position: absolute;
    top: 82px;
    height: 80%;
  }

  .eventSelectedBackground {
    display: flex;
    width: 100%;

    .eventSelectedBackgroundShadow {
      position: relative;
      z-index: 2;
      width: 40%;
      background-color: $sailgp-dark-blue;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 10;
        width: 50%;
        height: 100%;
        background-image: linear-gradient(to right, $sailgp-dark-blue, transparent);
      }
    }

    .eventSelectedBackgroundMedia {
      width: 60%;
      background-position: center;
      background-size: cover;

      &--video {
        background-color: #2dc100;

        .videoWrapper{
          position: unset;
          max-width: unset;
        }

        .eventSelectedVideoWidgetContainer {
          width: 100%;
          height: 100%;
          position: relative;

          .VideoWidgetContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

      }
    }
  }

  .eventSelectedArea {
    z-index: 3;
    display: flex;
    width: 100%;

    &__button-container {
      margin-top: 20px;
    }

    &__button {
      width: 120px;
      height: 40px;
      margin: 12px;
      transform: skewX(-10deg);
      border-radius: 0;
      background-color: #e23b25;
      color: #fff;
    }

    .eventSelectedAreaContainer {
      width: 40%;
      padding-left: 16px;

      .eventDate {
        margin-top: 40px;
        font-size: 20px;

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }

        &__small-margin {
          margin-top: 15px;
        }
      }

      .eventTitle {
        padding-top: 20px;
        font-size: 36px;
        font-weight: 700;

        @media screen and (max-width: 1440px) {
          font-size: 30px;
        }
      }

      .eventSubtitle,
      .eventLocation {
        font-size: 20px;
        font-weight: 700;

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }
      }

      .eventLocation {
        position: relative;
      }

      .countdownWrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 700;
        color: $sailgp-red;

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }

        .replayAvailableText {
          margin-right: 10px;
        }
      }

      .eventDescription {
        max-width: 70%;
        margin-top: 20px;
        font-size: 18px;
        color: #999;

        @media screen and (max-width: 1400px) {
          max-width: 80%;
          font-size: 14px;
        }
      }
    }
  }
}