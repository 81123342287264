@import "../../styles/core";

.rolex-watch-container {
  color: #fff;
  text-align: center;
  background-color: inherit;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-style: italic;
  font-weight: bold;

  ul {
    position: relative;
    display: inline-block;

    li {
      display: inline-block;
      font-size: 16px;
      list-style-type: none;
      padding: 0 8px;
      text-transform: uppercase;
      text-align: center;
      border-right: 1px solid $color-supercharge-red;

      &:last-child {
        border-right: none;
      }

      color: $color-medal-silver;

      span {
        display: block;
        font-size: 16px;
        color: $color-white;
      }
    }
  }
}

