@import "../../styles/core";

.popStatContainer {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $sailgp-deap-ocean-blue;
  max-height: 100%;
  font-style: italic;

  .sailgpWidgetAngle{
    border-top: 100px solid $sailgp-red;
    border-right: 25px solid transparent;
    height: 0;
    min-width: 50%;
  }

  .popStatLabel {
    font-size: 14px;
    font-weight: bold;
    color: white;
    position: absolute;
    left: 15px;
  }

  .popStatValue {
    justify-content: center;
    display: flex;
    position: absolute;
    right: 15px;
    color: white;
    font-size: 26px;
    font-weight: bold;
    font-style: italic;
    min-width: 35%;
    width: 35%;
  }

}





