@import "../../../../styles/core";

.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .videoWrapper {
    position: absolute;
    max-width: 30%;
    z-index: 5;
    cursor: move;
    border: black solid 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 4px;
    overflow: hidden;

    .closeIcon {
      background-color: rgba(3, 14, 14, 0.3);
      color: white;
      border-radius: 50%;
      position: absolute;
      z-index: 5;
      top: 10%;
      right: 10%;
      cursor: pointer;
      display: flex;
    }

    .controlsContainer {
      display: none;
    }
  }

  .seasonsContainer {
    background-color: $sailgp-dark-blue;
    overflow-y: auto;
    flex: 1;
    margin-top: 460px;
    height: 100%;
  }

  .sliderWrapper {
    padding: 16px;
    height: 50%;

    .seasonTitle {
      margin: 0 0 0 55px;
      font-size: 35px;
    }

    .sliderCentred {
      padding-left: 50px;
    }

    .slick-slider {
      display: grid;
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: auto;
    }

    .slick-arrow {
      width: 50px;
      height: 100%;
      background: #030e0e;
      opacity: 0.7;
      z-index: 4;
      cursor: pointer;
      position: absolute;

      &.slick-prev {
        left: 0;
      }


      &.slick-next {
        right: 0;
      }
    }

    .slick-next:hover, .slick-prev:hover {
      opacity: 0.9;
    }

    .slick-disabled {
      display: none !important;
    }

    //&:not(&--open) .eventThumbnail:hover {
    //  transform: scale(1.25) !important;
    //
    //}

    //&:not(&--open):hover .eventThumbnail {
    //  transform: translateX(-12.5%);
    //}
    //
    //&:not(&--open) .eventThumbnail:hover ~ .eventThumbnail {
    //  transform: translateX(12.5%);
    //}

  }
}



