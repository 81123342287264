@import "../../styles/core";

.raceScheduleWidgetContainer {
  height: 100%;
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  justify-content: space-evenly;

  .selectedRaceEvent {
    font-size: 24px;
    font-style: italic;
    font-weight: bold;
    padding: 0 16px 0px 16px;

    @include mq($until: 1185px) {
      font-size: 20px;
    }

  }

  .subtitleAndDate {
    font-size: 16px;
    padding-left: 16px;
  }

  .raceDayTimes {
    flex-direction: row;
    display: flex;

    .raceInfo {
      height: 100%;
      display: flex;
      background-color: $sailgp-dark-blue;
      padding: 0 16px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-white;
      opacity: 0.5;

      &.selectable {
        cursor: pointer;
      }

      &.active {
        text-decoration: underline $sailgp-red 2px;
        text-underline-offset: 6px;
        color: $color-white;
        opacity: 1;
      }
    }
  }
}
