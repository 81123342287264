@import "../../styles/core";

.countryFlag {
  position: absolute;
  width: 40px;
  top: -5%;


  .venueCountryImage {
    width: 100%;

  }

  .raceNumber {
    position: absolute;
    left: 7px;
    top: 15px;
    width: 40px;
    height: 18px;
    background-color: $sailgp-red;
    -webkit-clip-path: polygon(0px 27%, 100% 0px, 100% 100%, 0px 100%);
    clip-path: polygon(0px 27%, 100% 0px, 100% 100%, 0px 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;

    .number {
      padding-left: 12px;
    }
  }
}