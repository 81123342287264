@import '../../../../../styles/core';

@keyframes live-border-pulsate {
  from {
    border-color: rgba(226, 59, 37, 1);
  }
  to {
    border-color: rgba(226, 59, 37, 0);
  }
}


@keyframes live-dot-pulsate {
  from {
    background-color: rgba(226, 59, 37, 1);
  }
  to {
    background-color: rgba(226, 59, 37, 0);
  }
}


.eventThumbnail {
  margin: 40px 3px;
  position: relative;
  transition: all .2s ease-in-out;

  &--button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &:hover {
    transform: scale(1.1);
    z-index: 100;
  }

  .venueImage {
    width: 100%;
    border-radius: 4px;
  }

  .sliderThumbnailInfo {
    position: absolute;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;
    background: rgba(3, 14, 14, 0.4);
    z-index: 1;

    .seasonRibbon {
      width: 100%;
      background: $sailgp-red;
      position: absolute;
      text-align: center;
      line-height: 30px;
      letter-spacing: 1px;
      color: #f0f0f0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(45deg);
      top: 20px;
      right: -64px;
      box-shadow: 0 0 3px rgba(0, 0, 0, .3);

      &.notAvailable {
        background: $color-deep-ocean-blue;
      }
    }


    .slideEventInfo {
      display: flex;
      flex-direction: column;
      color: white;
      font-size: 22px;
      font-weight: bold;
      padding: 8%;
      height: 80%;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;

      .title {
        font-size: 22px;
        text-align: left;
        @include mq($until: 1300px) {
          font-size: 18px;
        }
      }

      .subTitle {
        font-size: 16px;
        text-align: left;
        @include mq($until: 1300px) {
          font-size: 14px;
        }
      }

      .upcomingContainer {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .background {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 120px;
        padding: 3px;
        background-color: $color-white;
        transform: skewX(-10deg);
        z-index: 25;
        box-shadow: 5px 5px $color-supercharge-red;

        &--red {
          background-color: $color-supercharge-red;
          box-shadow: 5px 5px $color-white;
        }

        .upcomingText {
          color: $color-supercharge-red;
          font-size: 16px;
          margin: 0;
          z-index: 30;
          text-transform: uppercase;

          &--white {
            color: $color-white;
          }

          @include mq($until: 1300px) {
            font-size: 12px;
          }
        }

        @include mq($until: 1300px) {
          height: 20px;
          width: 100px;
        }

      }
    }
  }

  .selectedBorder {
    border: solid 4px #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
  }

  &.liveEvent {

    .liveBorder {
      -webkit-animation-name: live-border-pulsate;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      -webkit-animation-direction: alternate;
      -webkit-animation-duration: 1s;
      border: solid 4px #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
    }

    .liveState {
      padding: 3%;
      position: absolute;
      top: 4%;
      right: 5%;
      display: flex;
      color: rgba(226, 59, 37, 1);
      font-weight: bold;
      letter-spacing: 1.2px;
      z-index: 5;
      border-radius: 4px;
      background-color: white;
      border: 1px rgba(11, 75, 183, 1);
      align-items: center;


      .liveText {
        margin-right: 10px;
      }

      .liveDot {
        -webkit-animation-name: live-dot-pulsate;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease;
        -webkit-animation-direction: alternate;
        -webkit-animation-duration: 1s;

        box-shadow: 0px 0px 0px 2px #e23b25;
        background-color: rgba(226, 59, 37, 1);
        border-radius: 50%;
        width: 16px;
        height: 16px;

      }
    }

  }

  &.upcomingEvent {
    .sliderThumbnailInfo {
      background-color: rgba(3, 14, 14, 0.8);
    }

  }
}
