@import "../../styles/core";

.popoverContainer {

  p {
    font-size: 21px;
    font-weight: bold;
  }

  h1 {
    font-style: italic;
    font-size: 50px;
    line-height: 45.5px;
  }

  h2 {
    font-size: 40px;
    font-style: italic;
    position: absolute;
    top: 18%;
  }

  .MuiPopover-paper {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    background: #061a1be6;
    margin: 0;
    padding: 0;
    font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-transform: uppercase;
    left: 0 !important;
    top: 0 !important;
    transform-origin: unset !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .exitContainer {
    padding-left: 50px;
    padding-top: 20px;

    .exitButton {
      display: flex;
      flex-direction: row;
      background: none;
      border: none;
      font-style: italic;
      color: white;
      cursor: pointer;

      .exitIcon {
        height: 22px;
        width: 27px;
        padding-right: 15px;
        padding-top: 20px;
      }
    }
  }


  .middleSectionContainer {

    &__logoContainer {
      display: flex;
      justify-content: center;
      margin-top: 100px;

      img {
        height: 125px;
      }
    }

    .textContainer {
      display: flex;
      justify-content: center;

      .titleContainer {
        margin-top: 60px;

        &__next {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 40px;
        }

        &__name {
          margin-top: 60px;
          margin-bottom: 0;
        }

        &__subtitle {
          margin-top: 0;
        }
      }
    }

    .countdownContainer {
      display: flex;
      justify-content: center;

      .countdown {
        color: white;
        font-size: 200px;
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }


  .endSection {
    position: relative;
  }

  .skipButton {
    transform: skewX(-15deg);
    background-color: $color-supercharge-red;
    color: white;
    border-radius: 0;
    padding: 25px 30px;
    height: 0;
    position: absolute;
    bottom: 10%;

    .skipIcon {
      margin-right: 3px;
      stroke: white;
    }

    .skipText {
      padding-left: 20px;
      margin: 0;
    }


    &:hover {
      background-color: white;
      color: $color-supercharge-red;
      stroke: $color-supercharge-red;

      .skipIcon {
        stroke: $color-supercharge-red;
      }
    }

  }

}