@import "../../styles/core";

.predictedToFinishWidgetContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: $sailgp-deap-ocean-blue;
  border-left: 5px solid var(--team-color, $sailgp-deap-ocean-blue);
  color: white;
  box-sizing: border-box;

  .leaderImages {
    margin-left: 20px;
    font-size: 24px;
    display: flex;

    .countryImage {
      transform: skewX(-10deg);

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .countryLabel {
      font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      margin-left: 8px;
      font-style: italic;
      font-weight: bold;
    }
  }

  .popStatWrapper {
    max-height: 45px;
    overflow: hidden;
  }
}