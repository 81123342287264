$font-path: '../src/styles/fonts';

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-Regular.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-Regular.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-Regular.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-Regular.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-Regular.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-Regular.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-RegularItalic.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-RegularItalic.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-Light.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-Light.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-Light.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-LightItalic.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-LightItalic.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-Medium.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-Medium.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-Medium.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-Semibold.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-Semibold.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-Semibold.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskWeb';
	font-display: swap;
	src: url('#{$font-path}/FoundersGroteskWeb-SemiboldItalic.eot');
	src: url('#{$font-path}/FoundersGroteskWeb-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/FoundersGroteskWeb-SemiboldItalic.woff2') format('woff2'),
		 url('#{$font-path}/FoundersGroteskWeb-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'FoundersGroteskWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
