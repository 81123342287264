@import "../../../../styles/core";

.videoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .videoContainerAbsolute {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */

    &--raceSchedule {
      height: 300px;
    }

    ReactPlayer {
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.disableControls {
  pointer-events: none;
}