@import "../../styles/core";

.CombinedBoatStatContainer {
  height: 100%;

  .MuiTableCell-root {
    border: none;
  }

  .tableContainerClass {
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $sailgp-grey-blue;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }

  .table {

    .headerRow {
      background-color: $sailgp-black;
      height: 45px;

      .headerCell {
        font-size: 14px;
        font-weight: 500;
        font-style: italic;
        padding: 0 6px;
        background-color: unset;

        &.medium {
          font-size: 12px;
          font-weight: 500;
          padding: 0 4px;
        }
      }
    }

    .tableRow {
      background-color: $color-deep-ocean-blue;
      color: $color-white;
      font-weight: 600;
      font-size: 14px;
      font-style: italic;

      &.boatFinished {
        border-bottom: 1.5px solid $color-supercharge-red;
      }

      &.boatNotInRace {
        color: rgba(255, 255, 255, 0.25)
      }


      &:nth-last-child(1) {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
      }

      .tableCell {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;

        &.medium {
          font-size: 12px;
          padding-left: 2px;
          padding-right: 2px;
        }

        .unit {
          font-size: 8px;
        }

        .boatTime {
          min-width: 60px;

          &.finalRace {
            min-width: 80px;
          }
        }

        &.boatFinished {
          background-image: url(../../assets/boat-finished-dazzle.svg);
          background-repeat: no-repeat;
          background-size: cover;
        }

        .positionLayout {
          position: relative;
          display: flex;
          align-items: center;

          .positionNumber {
            min-width: 20px;
          }

          .boatIcons {
            height: 14px;
            width: 8px;
            margin: 0 6px;
            min-width: 20px;

            &.finalRace {
              height: 42px;
              width: 24px;
            }

            &.boatIconsOutOfRace {
              height: 15px;
              width: 16px;
            }

            // Use CSS custom properties for dynamic team colors
            fill: var(--team-color, $sailgp-deap-ocean-blue);
            stroke: var(--team-color, $sailgp-deap-ocean-blue);
            display: block;

            // Special case for JPN
            &[data-team-code="JPN"] {
              stroke: $color-black;
              stroke-width: 1px;
            }
          }


          .alertIcons {
            justify-content: center;
            width: 19px;
            height: 19px;
            font-size: inherit;
            color: $color-white;
            background-color: $color-alert-penalty;
            margin-left: 5px;

            &.medium {
              font-size: inherit;
              width: 16px;
              height: 16px;
              margin-left: 4px;
            }

          }

        }
      }
    }
  }
}
