@import "../../styles/core";

.mobilePromptContainer {
  background-color: #061a1b;

  .mobilePromptWrapper {
    display: flex;
    flex-direction: column;
    padding: 0% 5%;
    color: $color-white;
    height: 100%;
    justify-content: center;
    align-items: center;
    .heading {
      text-align: center;
    }

    .contentLink {
      font-weight: 600;
      font-size: 20px;
      color: $sailgp-red;
    }
  }

}
