// SETTINGS
@import "settings/settings.colors";
@import "./slick";
@import "./slick-theme";
@import "settings/settings.global";
@import "node_modules/sass-mq/mq";

.communicationTool {
  position: absolute;
  bottom: 50px;
  left: 50px;
  z-index: 1;
}

.grid {
  height: 100vh !important;
}


.gridItem {
  overflow: hidden;
}



