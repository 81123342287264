@import "../../../styles/core";

.VideoWidgetContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  background-color: $sailgp-black;

  &--raceSchedule {
    background-color: #030e0e;
    position: absolute;
    left: 30%;
    overflow: hidden;
  }

  > .controlsContainer {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1;
  }

  > .videoContainerRelative {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    position: relative;

    &--live {
      width: unset;
      height: 300px;
    }
  }
}

