@import "../../styles/core";

.headBrandingContainer {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  img{
    width: stretch;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  }
}
